@use "dsy" as *;

.avatar {
  flex-shrink: 0;

  @include media-breakpoint-to(xs) {
    height: 70px;
    width: 70px;
  }

  @include media-breakpoint-between(s, sm) {
    height: 76px;
    width: 76px;
  }

  @include media-breakpoint-from(m) {
    height: 138px;
    width: 138px;
  }
}

.avatar-image {
  background-color: $color-garlic;
}

.cookingpot {
  @include media-breakpoint-to(xs) {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    margin-bottom: 16px;
  }
}

.cookingpot-image {
  flex-shrink: 0;

  @include media-breakpoint-to(xs) {
    height: 129px;
    width: 160px;
  }

  @include media-breakpoint-from(sm) {
    height: 146px;
    width: 181px;
  }
}

.userinfo {
  margin: auto 0;
  width: 100%;
}

.userinfo-data {
  @include media-breakpoint-to(sm) {
    min-height: 83px;
  }

  @include media-breakpoint-from(m) {
    min-height: 146px;
  }
}

.icon {
  color: $color-corporate-green-medium;

  &-xs {
    font-size: 18px;
  }

  &-text {
    margin-left: 2px;
  }
}

.grid-gap-6 {
  grid-gap: 3 * $unit;
}

.between-s-m {
  @include media-breakpoint-between(s, sm) {
    display: none;
  }
}

.explanation {
  @include media-breakpoint-to(xs) {
    text-align: center;
  }
}

.ad-container {
  // Increase specificity to override default margin
  body[data-ads-site-type] & {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }

  body[data-ads-site-type="desktop"] & {
    max-width: $screen-m - $skyscraper-width;
    margin-right: calc(((100vw - #{$screen-m}) / 2) + #{$skyscraper-width});
  }
}

.more-btn {
  &-hide {
    display: none;
  }

  &-show-only-tablet {
    @include media-breakpoint-to(xs) {
      display: none;
    }

    @include media-breakpoint-from(m) {
      display: none;
    }
  }
}

.whitespace-no-wrap {
  white-space: nowrap;
}
